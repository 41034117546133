import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import axios from "axios"
import Header from '../components/Header'

const ActivityWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 1em 1em;
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
	width: 100%;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1em;
	text-align: left;
`
const ErrorTitle = styled.h1`
	font-size: 2em;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const Location = styled.span`
	color: ${colors.secondary};
	text-align: left;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-right: 0.5em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function CreateActivity() {
	const { context } = useContext(UserContext);
	const [error, setError] = useState('');
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		// Check if server is up and if login token is expired :
		axios.get(`${Constants.BACKEND_URL}user/${context["user"]._id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get User ok : ", response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});

		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("check_user_notifs ", response.data);
				set_notif_count(response.data.notif_count);
				set_new_chat_message(response.data.new_chat_message);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])
	function validate_form() {
		console.log("script axios context['auth_token'] : " + context["auth_token"]);
		if ("" === $('#title').val()) {
			setError("Titre obligatoire !");
			return false;
		}
		if ("" === $('#date').val()) {
			setError("Date obligatoire !");
			return false;
		}
		return true;
	}
	function cancel_create() {
		if (window.confirm("Annuler la création de sortie ?")) {
			window.location.href = "/";
		}
	}
	function create_activity() {
		console.log("----------------------------- ");
		var activity = {
			"title": $('#title').val(),
			"date": $('#date').val(),
			"date_hour": $('#date_hour').val(),
			"date_minute": $('#date_minute').val(),
			"max_user_number": $('#max_user_number').val(),
			"access": $('#access').val(),
			"type": $('#type').val(),
			"validation": $('#validation').val(),
			"city": $('#city').val(),
			"price": "Gratuit"
		};
		console.log("create_activity : ", activity);
		if (!validate_form()) { return; }

		axios.post(`${Constants.BACKEND_URL}activity`, activity,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("script post axiazdazdos");
				console.log(response.data);
				if (response.data._id) {
					navigate('/activity/' + response.data._id); return;
				}
			}).catch(error => {
				console.error(error);
				if (error.response && error.response.data && error.response.data.error) {
					setError(error.response.data.error);
				} else {
					setError(error);
				}
			});
	}
	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			<ActivityWrapper>
				<ActivityDetails>
					<TitleWrapper>
						<Location><StyledLink $isFullLink onClick={() => create_activity()}>Sauvegarder / Publier</StyledLink></Location>
						<Location><StyledLink $isFullLink onClick={() => cancel_create()}>Annuler</StyledLink></Location>
					</TitleWrapper>
					<TitleWrapper>
						<ErrorTitle>{error ? ("Erreur : " + error) : ("")}</ErrorTitle>&nbsp;
					</TitleWrapper>
					<TitleWrapper>
						<Location><input style={{ width: '30em' }} id="title" placeholder="Titre obligatoire ! " /></Location>
					</TitleWrapper>
					<TitleWrapper>
						<Title>Date*&nbsp;&nbsp;&nbsp;&nbsp;</Title>
						<input id="date" type="date" style={{ width: '13em' }} />
					</TitleWrapper>
					<TitleWrapper>

						<Title>Heure&nbsp;:&nbsp;</Title>
						<select id="date_hour" style={{ width: '6em' }} defaultValue="19">
							<option value="00">00</option>
							<option value="01">01</option>
							<option value="02">02</option>
							<option value="03">03</option>
							<option value="04">04</option>
							<option value="05">05</option>
							<option value="06">06</option>
							<option value="07">07</option>
							<option value="08">08</option>
							<option value="09">09</option>
							<option value="10">10</option>
							<option value="11">11</option>
							<option value="12">12</option>
							<option value="13">13</option>
							<option value="14">14</option>
							<option value="15">15</option>
							<option value="16">16</option>
							<option value="17">17</option>
							<option value="18">18</option>
							<option value="19">19</option>
							<option value="20">20</option>
							<option value="21">21</option>
							<option value="22">22</option>
							<option value="23">23</option>
						</select>
						<Title>&nbsp;&nbsp;Minutes&nbsp;:&nbsp;</Title>
						<select id="date_minute" style={{ width: '6em' }} >
							<option value="00">00</option>
							<option value="05">05</option>
							<option value="10">10</option>
							<option value="15">15</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="30">30</option>
							<option value="35">35</option>
							<option value="40">40</option>
							<option value="45">45</option>
							<option value="50">50</option>
							<option value="55">55</option>
						</select>
					</TitleWrapper>
					<TitleWrapper>
						<Location><input id="max_user_number" type="number" defaultValue="6" size="50" /></Location>
						<Title>Nombre maximun de participants*</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location>
							<select id="access" size="3" defaultValue="public">
								<option value="public">Publique</option>
								<option value="link_only">Via le lien seulement</option>
							</select>
						</Location>
						<Title>Visibilité</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location>
							<select id="validation" size="2" defaultValue="automatic">
								<option value="automatic">Automatique</option>
								<option value="manual">Manuelle</option>
							</select>
						</Location>
						<Title>Validation des participants</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location>
							<select id="type" size="8" defaultValue="Divers">
								<option value="Divers">Divers</option>
								<option value="Jeux">Jeux</option>
								<option value="Sport">Sport</option>
								<option value="Repas">Repas</option>
								<option value="Boire un verre">Boire un verre</option>
								<option value="Spectacle">Spectacle</option>
								<option value="Danse">Danse</option>
								<option value="Enfants">Enfants</option>
							</select>
						</Location>
						<Title>Type de sortie</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location><input id="city" defaultValue="Paris" size="50"/></Location>
						<Title>Ville</Title>
					</TitleWrapper>
				</ActivityDetails>
			</ActivityWrapper>
		</div>
	)
}

export default CreateActivity
