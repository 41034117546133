import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import UserSmallCard from '../components/UserSmallCard'
import MessageCard from '../components/MessageCard'
import ReviewCard from '../components/ReviewCard'
import CreatorReviewCard from '../components/CreatorReviewCard'
import { StyledLink } from '../utils/style/Atoms'
import { SmallStyledLink } from '../utils/style/Atoms'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import { QRCodeSVG } from 'qrcode.react';
import Header from '../components/Header'
import dateFormat from 'dateformat';
import axios from "axios"
import Linkify from 'react-linkify';
import ModifyIcon from '../assets/modify.png'

const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1.2em;
`
const DateLine = styled.h1`
	margin: 0.1em;
	font-size: 1em;
	color: #f5e4c7;
`
const Description = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	text-align: left;
`
const TextLabels = styled.div`
	font-size: 0.8em;
`
const Organisateur = styled.div`
	font-size: 1em;
	color: #b9f4f3;
`
const Details = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	color: #f5e4c7;
`
const DetailsDescription = styled.div`
	margin: 0.1em;
	font-size: 1em;
	color: #f5e4c7;
`
const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ErrorTitle = styled.h1`
	font-size: 30px;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const UserListWrapper = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
`
const VerticalWrapper = styled.div`
	margin-top: 0.3em;
	margin-left: 0.5em;
	display: flex;
	flex-direction: column;
`
const HorizontalWrapper = styled.div`
	display: flex;
	flex-direction: line;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const HorizontalWrapperNoMargin = styled.div`
	display: flex;
	flex-direction: line;
`
const HorizontalWrapperCenter = styled.div`
	display: flex;
	flex-direction: line;
	justify-content: center;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5em;
	color: white;
	width: 100%;
`
const PictureAndButtonHeader = styled.div`
	display: flex;
	flex-direction: line;
	margin-left: 1em;
	color: white;
`
const Location = styled.span`
	color: ${colors.secondary};
`
const InputMessage = styled.span`
	width: 100%;
`
const MessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const Availability = styled.span`
	&:before {
		position: absolute;
		left: 0;
		top: 4px;
		height: 10px;
		width: 10px;
		border-radius: 5px;
		background-color: ${({ available }) => (available ? 'green' : 'red')};
		content: '';
	}
	padding-left: 20px;
	position: relative;
`
const CardImage = styled.img`
	width: 100%;
`
const PositionDiv = styled.img`
	position: absolute;
	border-radius: 50%;
`
const ModifyIconIllustration = styled.img`
	max-width: 28px;
	max-height: 28px;
`
function Activity() {
	const { id: queryId } = useParams();
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const { context } = useContext(UserContext);
	const [error, setError] = useState('');
	const [activityData, set_activity_data] = useState({});
	const [edit_page, set_edit_page] = useState(false);
	const [google_calendar_url, set_google_calendar_url] = useState("");
	const [activityUserLinkData, set_activity_user_link_data] = useState({});
	const [activityWaitlistUserLinkData, set_activity_waitlist_user_link_data] = useState({});
	const [activity_messages, set_activity_messages] = useState({});
	const [review_messages, set_review_messages] = useState({});
	const [participant, set_participant] = useState(false);
	const [participant_or_waitlist, set_participant_or_waitlist] = useState(false);
	const [show_confirm_attendance_button, set_show_confirm_attendance_button] = useState(false);
	const [creator, set_creator] = useState(false);
	const [inputSelectedFile, setInputSelectFile] = useState('')
	const [isLoading, setLoading] = useState(null);
	const [x_coordinate, set_x_coordinate] = useState(0);
	const [y_coordinate, set_y_coordinate] = useState(0);
	const [x_relative_coordinate, set_x_relative_coordinate] = useState(0);
	const [y_relative_coordinate, set_y_relative_coordinate] = useState(0);
	const [position_marker_size, set_position_marker_size] = useState(0);
	const [is_user_connected, set_is_user_connected] = useState(false);
	const marker_size_factor = 25;
	const navigate = useNavigate();

	useEffect(() => {
		var connected = false;
		if (!context["auth_token"] && localStorage.getItem('user')) {
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		if (context["auth_token"]) {
			connected = true;
		}
		set_is_user_connected(connected);
		if (!connected) {
			get_public_activity_data();
		} else {
			get_activity_data();
			axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("check_user_notifs ", response.data);
					set_notif_count(response.data.notif_count);
					set_new_chat_message(response.data.new_chat_message);
				}).catch(error => {
					console.error("get activities error : ", error);
				});
		}
	}, [])
	function set_toulouse_map_marker_position(data) {
		if (data) {
			if (document.getElementById("Toulouse_Map")) {
				var marker_size = window.innerWidth / marker_size_factor;
				var rect = document.getElementById("Toulouse_Map").getBoundingClientRect();
				set_x_relative_coordinate(data.x_coord * window.innerWidth + window.scrollX - marker_size * 1.5);
				set_y_relative_coordinate(data.y_coord * window.innerWidth + window.scrollY - marker_size * 1.5 + rect.top);
			}
		}
	}
	function get_public_activity_data() {
		console.log("get one get_public_activity_data " + queryId);
		axios.get(`${Constants.BACKEND_URL}activity/public/${queryId}`)
			.then(response => {
				console.log("get Activity public access : ", response.data);
				context["login_url_redirect"] = "/activity/" + queryId;
				set_activity_data(response.data);
				if (response.data.x_coord > 0) {
					var marker_size = window.innerWidth / marker_size_factor;
					set_position_marker_size(marker_size);
					set_toulouse_map_marker_position(response.data);
				}
				get_activity_user_link_data(response.data);
			}).catch(error => {
				var deleted_activity_data = {
					"_id": queryId,
					"title": "Sortie supprimée",
					"image": "user_deleted.jpg",
					"description": "Sortie supprimée",
					"user_title": "Supprimé",
					"max_user_number": "0",
					"current_user_number": "0"
				};
				set_activity_data(deleted_activity_data);
				console.error(error);
			});
	}
	function get_activity_data() {
		console.log("get one Activity " + queryId);
		axios.get(`${Constants.BACKEND_URL}activity/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get Activity : ", response.data);
				set_activity_data(response.data);
				if (response.data.x_coord > 0) {
					var marker_size = window.innerWidth / marker_size_factor;
					set_position_marker_size(marker_size);
					set_toulouse_map_marker_position(response.data);
				}
				generate_google_calendar_url(response.data);
				get_activity_user_link_data(response.data);
			}).catch(error => {
				var deleted_activity_data = {
					"_id": queryId,
					"title": "Sortie supprimée",
					"image": "user_deleted.jpg",
					"description": "Sortie supprimée",
					"user_title": "Supprimé",
					"max_user_number": "0",
					"current_user_number": "0"
				};
				set_activity_data(deleted_activity_data);
				console.error(error);
			});
	}
	function generate_google_calendar_url(data) {
		var html_url_title = data.title.replace(/ /g, '+');
		var html_url_description = "";
		if (data.description) {
			html_url_description = data.description.replace(/ /g, '+').replace(/\n/g, '%0A');
		}
		var html_url_location = data.city;
		if (data.address_location) {
			html_url_location = data.address_location.replace(/ /g, '+').split('\n')[0] + ", " + data.city;
		}
		if (data.meet_address_location) {
			html_url_location = data.meet_address_location.replace(/ /g, '+').split('\n')[0] + ", " + data.city;
		}
		var html_url_date_start = data.date.replace(/-/g, '').replace(/:/g, '').replace(/\./g, '').slice(0, -2);
		let html_url_date_end = html_url_date_start;
		let date = new Date(data.date);
		if (date) {
			date.setHours(date.getHours() + 2);
			html_url_date_end = date.toISOString().replace(/[-:]/g, '').split('.')[0] + "00";
		}
		var html_url_date = html_url_date_start + "/" + html_url_date_end + "&ctz=Europe/Paris";
		var url = "https://www.google.com/calendar/render?action=TEMPLATE&text="
			+ html_url_title + "&dates=" + html_url_date + "&details=" + html_url_description + "&location=" + html_url_location;
		set_google_calendar_url(url);
	}
	function get_activity_user_link_data(data) {
		axios.get(`${Constants.BACKEND_URL}activity_user_link/activity/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get activityUserLinkData : ", response.data);
				var activity_user_link_array = [];
				var activity_waitlist_user_link_array = [];
				for (var key in response.data) {
					var obj = response.data[key];
					console.log("get activityUserLinkData  obj: ", obj);
					if (obj.status === 'waitlist') {
						console.log("get activityUserLinkData  waitlist obj: ", obj);
						activity_waitlist_user_link_array.push(obj);
					} else {
						activity_user_link_array.push(obj);
					}
				}
				set_activity_user_link_data(activity_user_link_array);
				set_activity_waitlist_user_link_data(activity_waitlist_user_link_array);
				if (Array.isArray(response.data)) {
					console.log("get TODODZE : ", response.data);
					var participantItemFound = response.data.find(item => {
						set_toulouse_map_marker_position(data);
						return (item.userId === context["user"]._id && (item.status === "creator" || item.status === "accepted"))
					});
					if (participantItemFound !== undefined) {
						console.log("get participantItemFound : ", participantItemFound);
						set_participant(true);
						set_creator(participantItemFound.status === "creator");
						get_activity_messages(data);
					} else {
						get_review_messages(data);
					}
					var participant_or_waitlist_ItemFound = response.data.find(item => {
						return (item.userId === context["user"]._id)
					});
					if (participant_or_waitlist_ItemFound !== undefined) {
						console.log("get participant_or_waitlist_ItemFound : ", participant_or_waitlist_ItemFound);
						set_participant_or_waitlist(true);
					}
				}
			}).catch(error => console.error(error));
	}
	function get_activity_messages(data) {
		axios.get(`${Constants.BACKEND_URL}message/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_activity_messages : ", response.data);
				set_activity_messages(response.data.reverse())
				var msg_already_sent = false;
				for (var activity_message of response.data) {
					if (activity_message.userId === context["user"]._id &&
						activity_message.content.includes("Je confirme ma participation")) {
						msg_already_sent = true;
					}
				}
				if (!msg_already_sent) {
					Date.prototype.addHours = function (h) {
						this.setTime(this.getTime() + (h * 60 * 60 * 1000));
						return this;
					}
					const date_plus_fifty_hours = new Date().addHours(50);
					console.log("(activityData.date : ", data.date);
					const activity_date_obj = new Date(data.date);
					console.log("date_plus_fifty_hours : ", date_plus_fifty_hours);
					console.log("activity_date_obj : ", activity_date_obj);
					if (activity_date_obj < date_plus_fifty_hours) {
						set_show_confirm_attendance_button(true);
					}
				}
				get_review_messages(data);
			}).catch(error => console.error(error));
	}
	function get_review_messages(data) {
		axios.get(`${Constants.BACKEND_URL}review/activity/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_review_messages : ", response.data);
				set_review_messages(response.data);
				set_toulouse_map_marker_position(data);
			}).catch(error => console.error(error));
	}
	function register_to_activity() {
		axios.post(`${Constants.BACKEND_URL}activity_user_link`, {
			"activityId": queryId,
			"activity_userId": activityData.userId,
			"activity_title": activityData.title,
			"activity_image": activityData.image,
			"activity_city": activityData.city,
			"activity_date": activityData.date
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("register_to_activity : ", response.data);
				get_activity_data();
			}).catch(error => console.error(error));
	}
	function unregister_to_activity() {
		console.log("unregister_to_activity context['auth_token'] : " + context["auth_token"]);
		if (window.confirm("Annuler l'inscription ? Un message sera envoyé aux participants.")) {
			axios.delete(`${Constants.BACKEND_URL}activity_user_link/${queryId}`,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("unregister_to_activity : ", response.data);
					axios.post(`${Constants.BACKEND_URL}message`, {
						"activityId": queryId,
						"content": "Je ne participe plus"
					}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
						.then(response => {
							console.log("send message response : ", response.data);
							window.location.reload();
						}).catch(error => console.error(error));
					$('#message').val("");
				}).catch(error => console.error(error));
		}
	}
	function validate_form() {
		console.log("script axios context['auth_token'] : " + context["auth_token"]);
		if ("" === $('#title').val()) {
			setError("Titre obligatoire !");
			return false;
		}
		if ("" === $('#date').val()) {
			setError("Date obligatoire !");
			return false;
		}
		return true;
	}
	function delete_activity() {
		console.log("delete_activity context['auth_token'] : " + context["auth_token"]);
		if (window.confirm("Supprimer la sortie ? ATTENTION cette action est irréversible !")) {
			axios.delete(`${Constants.BACKEND_URL}activity/${queryId}`,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("delete_activity : ", response.data);
					navigate('/'); return;
				}).catch(error => console.error(error));
		}
	}
	function save() {
		console.log("inputSelectedFile : ", inputSelectedFile);
		setLoading(true);
		var activity = {
			"_id": queryId,
			"title": $('#title').val(),
			"description": $('#description').val(),
			"private_description": $('#private_description').val(),
			"important": $('#important').val(),
			"recommanded_age": $('#recommanded_age').val(),
			"type": $('#type').val(),
			"city": $('#city').val(),
			"price": $('#price').val(),
			"date": $('#date').val(),
			"date_hour": $('#date_hour').val(),
			"date_minute": $('#date_minute').val(),
			"duration": $('#duration').val(),
			"max_user_number": $('#max_user_number').val(),
			"additional_user_number": $('#additional_user_number').val(),
			"additional_user_surname": $('#additional_user_surname').val(),
			"address_location": $('#address_location').val(),
			"meet_address_location": $('#meet_address_location').val(),
			"access": $('#access').val(),
			"validation": $('#validation').val()
		};
		if (x_coordinate > 0) {
			activity["x_coord"] = x_coordinate;
			activity["y_coord"] = y_coordinate;
		} else if (x_coordinate === -1) {
			// Delete coordinate
			activity["x_coord"] = 0;
			activity["y_coord"] = 0;
		}
		console.log("create_activity : ", activity);
		if (!validate_form()) { return; }
		const formData = new FormData();
		formData.append('image', inputSelectedFile[0]);
		formData.append('activity', JSON.stringify(activity));
		axios.put(`${Constants.BACKEND_URL}activity`, formData,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}`, 'Content-Type': 'multipart/form-data' } })
			.then(response => {
				console.log("save activity response : ", response.data);
				if (current_user_number > 1 && window.confirm("Notifier les participants de la modification ?")) {
					axios.get(`${Constants.BACKEND_URL}activity/notify/${queryId}`,
						{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
						.then(response => {
							console.log("activity notify: ", response.data);
						}).catch(error => console.error(error));
				}
				setLoading(true);
				window.location.reload();
			}).catch(error => console.error(error));
	}
	function modify() {
		set_edit_page(true);
		setTimeout(() => {
			set_toulouse_map_marker_position(activityData);
		}, "200");
	}
	function cancel() {
		if (window.confirm("Annuler les modifications ?")) {
			set_edit_page(false);
			setLoading(true);
			window.location.reload();
			setTimeout(() => {
				set_toulouse_map_marker_position(activityData);
			}, "200");
		}
	}
	function validate_user(activity_user_link_id) {
		console.log("validate_user : ", activity_user_link_id);
		axios.get(`${Constants.BACKEND_URL}activity_user_link/accept/${activity_user_link_id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("validate_user activity response : ", response.data);
				window.location.reload();
			}).catch(error => console.error(error));
	}
	var { image, current_user_number, max_user_number } = activityData
	function send_message() {
		axios.post(`${Constants.BACKEND_URL}message`, {
			"activityId": queryId,
			"content": $('#message').val()
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("send message response : ", response.data);
				get_activity_data();
			}).catch(error => console.error(error));
		$('#message').val("");
	}
	function confirm_attendance() {
		axios.post(`${Constants.BACKEND_URL}message`, {
			"activityId": queryId,
			"content": "Je confirme ma participation"
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("send message response : ", response.data);
				get_activity_data();
				set_show_confirm_attendance_button(false);
			}).catch(error => console.error(error));
		$('#message').val("");
	}
	function duplicate_activity() {
		if (window.confirm("Dupliquer la sortie ?")) {
			// Copy object to allow delete object private properties
			console.log("create_activity : ", activityData);
			const activityDuplicate = JSON.parse(JSON.stringify(activityData));
			delete activityDuplicate.created;
			delete activityDuplicate.modified;
			delete activityDuplicate.userId;
			delete activityDuplicate.user_city;
			delete activityDuplicate.user_image;
			delete activityDuplicate.user_title;
			delete activityDuplicate.view_count;
			delete activityDuplicate.score;
			delete activityDuplicate.current_user_number;
			delete activityDuplicate.__v;
			delete activityDuplicate._id;
			Date.prototype.addHours = function (h) {
				this.setTime(this.getTime() + (h * 60 * 60 * 1000));
				return this;
			}
			const date_plus_one_hour = new Date().addHours(1);
			console.log("date_plus_one_day : ", date_plus_one_hour);
			activityDuplicate.date_hour = dateFormat(date_plus_one_hour, "HH");
			activityDuplicate.date_minute = dateFormat(date_plus_one_hour, "MM");
			activityDuplicate.date = date_plus_one_hour;
			activityDuplicate.title = "DUPLIQUEE : " + activityDuplicate.title;
			console.log("create_activity : ", activityDuplicate);
			if (!validate_form()) { return; }
			axios.post(`${Constants.BACKEND_URL}activity`, activityDuplicate,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("script post axiazdazdos");
					console.log(response.data);
					if (response.data._id) {
						navigate('/'); return;
					}
				}).catch(error => console.error(error));
		}
	}
	function edit_review(review_id) {
		console.log("edit_review id : " + review_id);
		//navigate('/review/' + review_id); return;; return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}review/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate('/review/' + review_id); return;
			}).catch(error => {
				navigate('/review/' + review_id); return;
			});
	}
	function edit_message(message_id) {
		console.log("edit_message id : " + message_id);
		//navigate('/update_message/' + message_id); return;; return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}message/get_one/${message_id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate(`/update_message/message-${message_id}`); return;
			}).catch(error => {
				navigate(`/update_message/message-${message_id}`); return;
			});
	}
	function invite() {
		console.log("invite id : " + queryId);
		//navigate('/sendInvite/'); return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate('/send_invite/' + queryId); return;
			}).catch(error => {
				navigate('/send_invite/' + queryId); return;
			});
	}
	function saveCoordinate(e) {
		if (edit_page) {
			var rect = document.getElementById("Toulouse_Map").getBoundingClientRect();
			var x_coordinate_update = e.clientX / window.innerWidth;
			var y_coordinate_update = (e.clientY - rect.top) / window.innerWidth;
			var marker_size = window.innerWidth / marker_size_factor;
			set_position_marker_size(marker_size);
			set_x_coordinate(x_coordinate_update);
			set_y_coordinate(y_coordinate_update);
			set_x_relative_coordinate(e.pageX - marker_size * 1.5);
			set_y_relative_coordinate(e.pageY - marker_size * 1.5);
			//alert(" x_coordinate_update : " + x_coordinate_update + " y_coordinate_update : " + y_coordinate_update + "rect.top : " + rect.top);
		}
	}
	function delete_coordinate() {
		set_x_coordinate(-1);
		set_y_coordinate(-1);
		set_x_relative_coordinate(-1);
		set_y_relative_coordinate(-1);
		set_position_marker_size(0);
	}
	function getAge(dateString) {
		if (!dateString) {
			return dateString;
		}
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	function toDateString(date) {
		var pad = function (num) {
			return (num < 10 ? '0' : '') + num;
		};
		try {
			var date_object = new Date(date);
			return date_object.getFullYear() +
				'-' + pad(date_object.getMonth() + 1) +
				'-' + pad(date_object.getDate());
		} catch {
			return "";
		}
	}
	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			<TopContainer>
				<ActivityWrapper>
					<PictureAndButtonHeader>
						<a href={`${Constants.BACKEND_URL}images/${image}`}>
							<div className="center-cropped" style={{
								backgroundImage: `url('${Constants.BACKEND_URL}images/${image}')`,
								width: '8em', height: '8em', borderRadius: '20%', marginLeft: '-8px'
							}} />
						</a>
						<VerticalWrapper>
							{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
							{(creator || (context["user"] && context["user"].moderator)) && edit_page && !isLoading && (
								<HorizontalWrapper>
									<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink></Location>
									<Location>&nbsp;</Location><Location>&nbsp;</Location>
									<Location><StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink></Location>
								</HorizontalWrapper>
							)}
							<HorizontalWrapper>
								{(creator || (context["user"] && context["user"].moderator)) && !edit_page &&
									(!activityData.activity_is_date_in_past || (context["user"] && context["user"].moderator)) && (
										<StyledLink $isFullLink onClick={() => modify()}>Modifier</StyledLink>
									)}
								{creator && !edit_page && (
									<StyledLink $isFullLink onClick={() => duplicate_activity(true)}>Dupliquer</StyledLink>
								)}
							</HorizontalWrapper>
							<HorizontalWrapperNoMargin>
								{(creator || (context["user"] && context["user"].moderator)) && !edit_page && Array.isArray(activityUserLinkData) && activityUserLinkData.length === 1 && (
									<SmallStyledLink $isFullLink onClick={() => delete_activity(true)}>Supprimer</SmallStyledLink>
								)}
								{!activityData.activity_is_date_in_past && is_user_connected && !edit_page && (
									<SmallStyledLink $isFullLink style={{ marginBottom: '0.2em', marginLeft: '0.3em' }}
										onClick={() => invite()}>Inviter</SmallStyledLink>
								)}
							</HorizontalWrapperNoMargin>
							{typeof creator == "boolean" && !creator && !edit_page && !activityData.activity_is_date_in_past && is_user_connected && (
								(participant_or_waitlist === false) ? (
									<StyledLink $isFullLink onClick={() => register_to_activity()}>Participer</StyledLink>
								) : (
									<StyledLink $isFullLink onClick={() => unregister_to_activity()}>Annuler l'inscription</StyledLink>
								)
							)}
							{!activityData.activity_is_date_in_past && is_user_connected && participant && show_confirm_attendance_button && (
								<StyledLink $isFullLink style={{ marginTop: '8px' }} onClick={() => confirm_attendance()}>Confirmer ma participation</StyledLink>
							)}
							{!is_user_connected && (
								<LoaderWrapper>
									<StyledLink $isFullLink to={`/login`} >En savoir plus</StyledLink>
								</LoaderWrapper>
							)}
							{edit_page && <TitleWrapper><Location><input type="file" onChange={(e) => setInputSelectFile(e.target.files)} /></Location><Title>Modifier l'image</Title></TitleWrapper>}
							{edit_page ? (<TitleWrapper>
								<Location><input id="title" style={{ width: '20em' }} placeholder="Titre obligatoire ! " defaultValue={activityData.title} /></Location><Title>Titre*</Title></TitleWrapper>
							) : (<Title>{activityData.title}</Title>)}
							{edit_page ? (<div>
								<TitleWrapper>
									<Title>Date*&nbsp;&nbsp;&nbsp;&nbsp;</Title>
									<input id="date" type="date" style={{ width: '13em' }} defaultValue={toDateString(activityData.date)} />
								</TitleWrapper>
								<TitleWrapper>
									<Title>Heure&nbsp;:&nbsp;</Title>
									<select id="date_hour" style={{ width: '4em' }} >
										<option value="00" selected={activityData.date_hour === "00"} >00</option>
										<option value="01" selected={activityData.date_hour === "01"} >01</option>
										<option value="02" selected={activityData.date_hour === "02"} >02</option>
										<option value="03" selected={activityData.date_hour === "03"} >03</option>
										<option value="04" selected={activityData.date_hour === "04"} >04</option>
										<option value="05" selected={activityData.date_hour === "05"} >05</option>
										<option value="06" selected={activityData.date_hour === "06"} >06</option>
										<option value="07" selected={activityData.date_hour === "07"} >07</option>
										<option value="08" selected={activityData.date_hour === "08"} >08</option>
										<option value="09" selected={activityData.date_hour === "09"} >09</option>
										<option value="10" selected={activityData.date_hour === "10"} >10</option>
										<option value="11" selected={activityData.date_hour === "11"} >11</option>
										<option value="12" selected={activityData.date_hour === "12"} >12</option>
										<option value="13" selected={activityData.date_hour === "13"} >13</option>
										<option value="14" selected={activityData.date_hour === "14"} >14</option>
										<option value="15" selected={activityData.date_hour === "15"} >15</option>
										<option value="16" selected={activityData.date_hour === "16"} >16</option>
										<option value="17" selected={activityData.date_hour === "17"} >17</option>
										<option value="18" selected={activityData.date_hour === "18"} >18</option>
										<option value="19" selected={activityData.date_hour === "19"} >19</option>
										<option value="20" selected={activityData.date_hour === "20"} >20</option>
										<option value="21" selected={activityData.date_hour === "21"} >21</option>
										<option value="22" selected={activityData.date_hour === "22"} >22</option>
										<option value="23" selected={activityData.date_hour === "23"} >23</option>
									</select>
									<Title>&nbsp;&nbsp;Minutes&nbsp;:&nbsp;</Title>
									<select id="date_minute" style={{ width: '4em' }} >
										<option value="00" selected={activityData.date_minute === "00"} >00</option>
										<option value="05" selected={activityData.date_minute === "05"} >05</option>
										<option value="10" selected={activityData.date_minute === "10"} >10</option>
										<option value="15" selected={activityData.date_minute === "15"} >15</option>
										<option value="20" selected={activityData.date_minute === "20"} >20</option>
										<option value="25" selected={activityData.date_minute === "25"} >25</option>
										<option value="30" selected={activityData.date_minute === "30"} >30</option>
										<option value="35" selected={activityData.date_minute === "35"} >35</option>
										<option value="40" selected={activityData.date_minute === "40"} >40</option>
										<option value="45" selected={activityData.date_minute === "45"} >45</option>
										<option value="50" selected={activityData.date_minute === "50"} >50</option>
										<option value="55" selected={activityData.date_minute === "55"} >55</option>
									</select>
								</TitleWrapper>
							</div>
							) : (<DateLine>{activityData.date_str} &nbsp;&nbsp;&nbsp;{edit_page ? ("") : (<span>{activityData.duration}</span>)}&nbsp;&nbsp;&nbsp;&nbsp;
								{current_user_number} / {max_user_number}&nbsp;<Availability available={"" + (current_user_number === max_user_number)}></Availability>
							</DateLine>)}
							{edit_page ? (<TitleWrapper>
								<Location><input id="duration" defaultValue={activityData.duration} /></Location><Title>Durée</Title></TitleWrapper>
							) : ("")}
							<TitleWrapper><Organisateur>Organisateur&nbsp;:&nbsp;&nbsp;{activityData.user_title}</Organisateur></TitleWrapper>
						</VerticalWrapper>
					</PictureAndButtonHeader>
				</ActivityWrapper>
				<ActivityWrapper>
					<ActivityDetails>
						<TitleWrapper>
							<ErrorTitle>{error && ("Erreur : " + error)}</ErrorTitle>
						</TitleWrapper>
						{edit_page ? (<TitleWrapper>
							<textarea id="description" placeholder="Description affichée publiquement"
								rows={12} style={{ width: '96%' }} maxLength={10000} wrap="soft" defaultValue={activityData.description} /></TitleWrapper>
						) : (
							<Description><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.description}</div></Linkify></Description>
						)}
						{edit_page ? (<TitleWrapper>
							<textarea id="important" defaultValue={activityData.important} placeholder="Texte important"
								rows={1} style={{ width: '96%' }} maxLength={10000} /></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><mark>{activityData.important}</mark></HorizontalWrapperNoMargin>)}
						{edit_page ? (<TitleWrapper>
							<textarea id="private_description" placeholder="Description visible par les participants seulement"
								rows={6} style={{ width: '96%' }} maxLength={10000} wrap="soft" defaultValue={activityData.private_description} /></TitleWrapper>
						) : (
							<Description><DetailsDescription>Description&nbsp;visible&nbsp;par&nbsp;les&nbsp;participants&nbsp;seulement&nbsp;:&nbsp;&nbsp;</DetailsDescription><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.private_description}</div></Linkify></Description>
						)}
						{edit_page ? (<TitleWrapper>
							<Location><input id="city" defaultValue={activityData.city} /></Location><Title>Ville</Title></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><Details>Ville&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{activityData.city}</TextLabels></HorizontalWrapperNoMargin>)}

						{edit_page ? (<TitleWrapper>
							<textarea id="address_location" defaultValue={activityData.address_location} placeholder="Lieu approximatif affiché publiquement"
								rows={3} style={{ width: '96%' }} maxLength={3000} wrap="soft" /></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><Details>Lieu&nbsp;approximatif&nbsp;:&nbsp;&nbsp;</Details>
							<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.address_location}</div></Linkify></HorizontalWrapperNoMargin>
						)}
						{edit_page ? (<TitleWrapper>
							<textarea id="meet_address_location" defaultValue={activityData.meet_address_location} placeholder="Lieu exact de RDV visible par les participants seulement"
								rows={3} style={{ width: '96%' }} maxLength={3000} wrap="soft" /></TitleWrapper>
						) : (participant ? (
							<HorizontalWrapperNoMargin><Details>Lieu&nbsp;exact&nbsp;de&nbsp;RDV&nbsp;:&nbsp;&nbsp;</Details>
								<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.meet_address_location}</div></Linkify></HorizontalWrapperNoMargin>) : ("")
						)}
						{edit_page && (<TitleWrapper>
							<Location><input id="max_user_number" type="number" defaultValue={activityData.max_user_number} /></Location><Title>Nombre maximun de participants</Title></TitleWrapper>
						)}
						{edit_page && (<TitleWrapper>
							<Location><input id="additional_user_number" type="number" defaultValue={activityData.additional_user_number} /></Location><Title>Nombre de participant(s) non inscrit(s) sur {Constants.DOMAINE}</Title></TitleWrapper>
						)}
						{edit_page && (
							<input id="additional_user_surname" defaultValue={activityData.additional_user_surname} placeholder={`Prénoms des participant(s) non inscrit(s) sur ${Constants.DOMAINE}`}
								style={{ width: '96%' }} />
						)}
						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="access">
									<option value="public" selected={activityData.access === "public"} >Publique</option>
									<option value="link_only" selected={activityData.access === "link_only"}>Via le lien seulement</option>
								</select>
							</Location><Details>Visibilité</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Visibilité&nbsp;:&nbsp;&nbsp;</Details><TextLabels>
							{"public" === activityData.access ? ("Publique") : ("")}
							{"link_only" === activityData.access ? ("Via le lien seulement") : ("")}
							{"friends_only" === activityData.access ? ("Amis seulement") : ("")}
							{"private" === activityData.access ? ("Seulement moi") : ("")}</TextLabels>
						</TitleWrapper>)}
						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="validation">
									<option value="automatic" selected={activityData.validation === "automatic"}>Automatique</option>
									<option value="manual" selected={activityData.validation === "manual"}>Manuelle</option>
								</select>
							</Location><Details>Validation des participants</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Validation des participants&nbsp;:&nbsp;&nbsp;</Details><TextLabels>
							{"automatic" === activityData.validation ? ("Automatique") : ("")}
							{"manual" === activityData.validation ? ("Manuelle") : ("")}</TextLabels>
						</TitleWrapper>)}
						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="type">
									<option value="Divers" selected={activityData.type === "Divers"}>Divers</option>
									<option value="Jeux" selected={activityData.type === "Jeux"}>Jeux</option>
									<option value="Sport" selected={activityData.type === "Sport"}>Sport</option>
									<option value="Repas" selected={activityData.type === "Repas"}>Repas</option>
									<option value="Boire un verre" selected={activityData.type === "Boire un verre"}>Boire un verre</option>
									<option value="Spectacle" selected={activityData.type === "Spectacle"}>Spectacle</option>
									<option value="Danse" selected={activityData.type === "Danse"}>Danse</option>
									<option value="Enfants" selected={activityData.type === "Enfants"}>Enfants</option>
								</select>
							</Location><Details>Type de sortie</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Type de sortie&nbsp;:&nbsp;&nbsp;{activityData.type}</Details>
						</TitleWrapper>)}
						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="recommanded_age">
									<option value="" selected={activityData.recommanded_age === ""} ></option>
									<option value="moins de 30 ans" selected={activityData.recommanded_age === "moins de 30 ans"}>moins de 30 ans</option>
									<option value="30 - 60 ans" selected={activityData.recommanded_age === "30 - 60 ans"}>30 - 60 ans</option>
									<option value="plus de 60 ans" selected={activityData.recommanded_age === "plus de 60 ans"}>plus de 60 ans</option>
								</select>
							</Location><Details>Age conseillé (facultatif)</Details></TitleWrapper>
						) : ("")}
						{!edit_page && activityData.recommanded_age ? (<TitleWrapper><Details>Age conseillé&nbsp;:&nbsp;&nbsp;{activityData.recommanded_age}</Details>
						</TitleWrapper>) : ("")}
						{edit_page ? (<TitleWrapper>
							<Location><input id="price" defaultValue={activityData.price} /></Location><Title>Coût</Title></TitleWrapper>
						) : (<HorizontalWrapperNoMargin><Details>Coût&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{activityData.price}</TextLabels></HorizontalWrapperNoMargin>)}

						{activityData.created ? (
							<div>
								<TitleWrapper><Details>Derniere modification &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(activityData.modified), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Sortie crée le &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(activityData.created), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Nombre de vue &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{activityData.view_count}</TextLabels></TitleWrapper>
							</div>
						) : ("")}

					</ActivityDetails>
				</ActivityWrapper>

				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(activity_messages) && !edit_page && (
							activity_messages?.map((msg) => (
								<HorizontalWrapper key={`msg-${msg._id}`}>
									<MessageCard
										content={msg.content}
										created={msg.created}
										user_id={msg.userId}
										user_title={msg.user_title}
										user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
									/>
									{msg.userId === context["user"]._id && (
										<ModifyIconIllustration onClick={() => edit_message(msg._id)} src={ModifyIcon} />
									)}
								</HorizontalWrapper>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				{participant && !edit_page && (
					<MessagesWrapper>
						<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
						<InputMessage>
							<textarea id="message" rows={3} style={{ width: '98%' }} maxLength={1000} wrap="soft"
								placeholder="Ajouter un message visible par les participants seulement" />
						</InputMessage>
					</MessagesWrapper>
				)}
				<ActivityWrapper>
					&nbsp;
				</ActivityWrapper>

				{Array.isArray(activityWaitlistUserLinkData) && activityWaitlistUserLinkData.length > 0 && !edit_page && (
					<div>{activityWaitlistUserLinkData.length}&nbsp;participant(s) en liste d'attente</div>
				)}
				<UserListWrapper>
					{Array.isArray(activityWaitlistUserLinkData) && !edit_page && (
						activityWaitlistUserLinkData?.map((link) => (
							<div key={`link-${link._id}`}>
								<Link to={`/user/${link.userId}`}>
									<UserSmallCard
										title={link.user_title}
										label="&nbsp;"
										picture={`${Constants.BACKEND_URL}images/${link.user_image}`}
									/>
								</Link>
								{creator && !edit_page && !activityData.activity_is_date_in_past && (
									<StyledLink $isFullLink onClick={() => validate_user(link._id)}>Accepter</StyledLink>
								)}
							</div>
						)))}
				</UserListWrapper>
				{Array.isArray(activityUserLinkData) && activityUserLinkData.length > 0 && !edit_page && (
					<div>{current_user_number}&nbsp;participant(s)</div>
				)}
				<UserListWrapper>
					{Array.isArray(activityUserLinkData) && !edit_page && (
						activityUserLinkData?.map((link) => (
							<div key={`link-${link._id}`}>
								<Link to={`/user/${link.userId}`}>
									<UserSmallCard
										title={link.user_title}
										label={getAge(link.user_birthday)}
										picture={`${Constants.BACKEND_URL}images/${link.user_image}`}
									/>
								</Link>
							</div>
						)))}
				</UserListWrapper>

				<ActivityWrapper>
					<ActivityDetails>
						{!edit_page && activityData && activityData.additional_user_number !== 0 && (<HorizontalWrapperNoMargin><Details>+{activityData.additional_user_number}&nbsp;participant(s) non inscrit(s) sur {Constants.DOMAINE}&nbsp;:&nbsp;&nbsp;</Details>
							<Linkify><div style={{ whiteSpace: "pre-wrap" }}>{activityData.additional_user_surname}</div></Linkify></HorizontalWrapperNoMargin>
						)}
					</ActivityDetails>
				</ActivityWrapper>

				{Array.isArray(review_messages) && review_messages.length > 0 && (
					<div>{review_messages.length}&nbsp;avis sur l'expérience vécue</div>
				)}
				<ActivityWrapper>
					<CardsContainer style={{ width: '100%' }}>
						{Array.isArray(review_messages) && (
							review_messages?.map((review) => (
								<HorizontalWrapper style={{ width: '100%' }} key={`msg-${review._id}`}>
									{review.userId !== activityData.userId && (
										<ReviewCard
											content={review.comment}
											rating={review.rating}
											activityId={review.activityId}
											user_id={review.userId}
											user_title={review.user_title}
											user_image={`${Constants.BACKEND_URL}images/${review.user_image}`}
										/>
									)}
									{review.userId === activityData.userId && (
										<CreatorReviewCard
											content={review.comment}
											rating={review.rating}
											target_userId={review.target_userId}
											target_user_title={review.target_user_title}
											target_user_image={`${Constants.BACKEND_URL}images/${review.target_user_image}`}
											user_id={review.userId}
											user_title={review.user_title}
											user_image={`${Constants.BACKEND_URL}images/${review.user_image}`}
										/>
									)}
									{review.userId === context["user"]._id && (
										<ModifyIconIllustration onClick={() => edit_review(review._id)} src={ModifyIcon} />
									)}
								</HorizontalWrapper>
							)))}
					</CardsContainer>
				</ActivityWrapper>

			</TopContainer>
			{position_marker_size > 0 && !edit_page ? (
				<div>Position approximative :</div>
			) : ("")}
			{edit_page && x_coordinate >= 0 ? (
				<Location>
					<StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink>
					<StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink>
					<HorizontalWrapperCenter>
						<StyledLink $isFullLink onClick={() => delete_coordinate()}>Supprimer la position</StyledLink>
					</HorizontalWrapperCenter>
				</Location>
			) : ("")}
			{edit_page && x_coordinate >= 0 ? (
				<div>Sélectionne une position approximative, ATTENTION A NE PAS ZOOMER CAR CELA NE FONCTIONNE PAS AVEC LE ZOOM !</div>
			) : ("")}
			{position_marker_size > 0 || (edit_page && x_coordinate >= 0) ? (
				<CardImage id="Toulouse_Map" src={`${Constants.BACKEND_URL}images/Europe_map.jpg`} onClick={(e) => saveCoordinate(e)} alt="Toulouse_Map" />
			) : ("")}
			{x_relative_coordinate && x_relative_coordinate > 0 ? (
				<PositionDiv style={{
					top: `${y_relative_coordinate}px`, left: `${x_relative_coordinate}px`, position: "absolute",
					border: `${position_marker_size / 5}px solid red`, width: `${position_marker_size * 3}px`, height: `${position_marker_size * 3}px`
				}} />
			) : ("")}
			{!edit_page ? (
				<Location><div>&nbsp;</div><a href={google_calendar_url} target="_blank" rel="noreferrer" class="button_link">
					Ajouter à l'agenda Google</a><div>&nbsp;</div></Location>
			) : ("")}
			{!edit_page ? (
				<div>Partager cette sortie</div>
			) : ("")}
			{!edit_page ? (
				<QRCodeSVG value={`https://${Constants.DOMAINE}/activity/${queryId}`} marginSize='4' />
			) : ("")}
		</div>
	)
}

export default Activity
